<template>
	<div>
		<a-modal v-drag-modal v-model="repayModalShow" :title="$t('table.finance.customerFee.uploadRepayments')" :destroyOnClose="true" width="70%">
			<a-card :title="$t('table.finance.repayment.tabs.loanInfo')" size="small">
				<a-form-model layout="inline">
					<a-form-model-item>
						<a-input v-model="searchKeyword" placeholder="REG/Mobile/Name" :disabled="needAutoSearch" />
					</a-form-model-item>
					<a-form-model-item>
						<a-button type="primary" @click="searchLoan" v-t.preserve="'operate.search2'" :disabled="needAutoSearch"></a-button>
					</a-form-model-item>
					<a-table
						style="font-size: 12px"
						:columns="loanColumns"
						size="small"
						:rowKey="(record, index) => index"
						:dataSource="loanDataSource"
						:pagination="false"
						:row-selection="{
							fixed: true,
							type: 'radio',
							columnWidth: 100,
							columnTitle: this.$t('table.finance.cloums.choose'),
							onChange: changeRow,
							selectedRowKeys: selectedRowKeys
						}"
						:scroll="{ x: '70%' }"
						bordered
					>
						<a-statistic slot="money_slot" slot-scope="text" :precision="2" :value="text === null ? ' - ' : text" />
						<span slot-scope="obj" slot="action">
							<a-button type="primary" @click="Detail(obj)">{{ $t('page.checkDetail') }}</a-button>
						</span>
					</a-table>
				</a-form-model>
			</a-card>
			<a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
				<a-form-model-item :label="$t('table.finance.cloum.payType')">
					<a-radio-group v-model="formModelData.transactionTypeEnum">
						<a-radio
							:value="item"
							v-for="item in [1, 0, 2, 3, 4]"
							:key="item"
							:disabled="(needAutoFilling && item !== formModelData.transactionTypeEnum) || (needAutoSearch && item === 4)"
						>
							{{ $t(`table.finance.repayment.payTypeMap.${item}`) }}
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item :label="$t('table.finance.cloum.referenceNo')">
					<a-input v-model="formModelData.paymentId" :disabled="needAutoFilling" />
				</a-form-model-item>
				<a-form-model-item
					v-if="formModelData.transactionTypeEnum == 2 && loanColumnData.carInfos.length > 1"
					:label="$t('table.CustomerManagement.fromdata.licensePlateNumber')"
				>
					<a-select v-model="formModelData.carPlateNo">
						<a-select-option :value="item.id" v-for="item in loanColumnData.carInfos" :key="item.id">
							{{ item.registrationNumber }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item :label="$t('table.finance.cloum.actual')">
					<a-input-number v-model="formModelData.amount" :min="0" :precision="0" :disabled="needAutoFilling" />
				</a-form-model-item>
				<a-form-model-item :label="$t('table.finance.cloum.excutionTime')">
					<a-date-picker v-model="formModelData.transactionDate" :format="dateFormat" showTime type="date" :disabled="needAutoFilling" />
				</a-form-model-item>
				<a-form-model-item :label="$t('table.finance.cloum.exactTime')" v-if="parentSource === 'bankAccountChecking'">
					<a-time-picker v-model="formModelData.transactionTime" format="HH:mm:ss" type="date" />
				</a-form-model-item>
				<a-form-model-item :label="$t('table.finance.cloum.payCode')" v-if="!needAutoFilling">
					<UploadPreviewFile :uploadFilePath.sync="formModelData.transactionFiles" :folderPath="loanColumnData.loanId"></UploadPreviewFile>
				</a-form-model-item>
				<a-form-model-item label="Discount">
					<a-radio-group v-model="formModelData.isDiscount" :disabled="whetherToOverpay">
						<a-radio :value="0"> No </a-radio>
						<a-radio :value="1"> Yes </a-radio>
					</a-radio-group>
					<div v-if="formModelData.isDiscount === 1">
						<a-form-item label="Discount on Tracking Fee" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountTrackingAmount"
								v-model="formModelData.discountTrackingAmount"
								:precision="0"
								style="margin-right: 10px"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountTrackingAmount}` }}
						</a-form-item>
						<a-form-item label="Discount on Loan Maintenance Fee" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountMaintenanceAmount"
								v-model="formModelData.discountMaintenanceAmount"
								:precision="0"
								style="margin-right: 10px"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountMaintenanceAmount}` }}
						</a-form-item>
						<a-form-item label="Discount on Interest" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountInterestAmount"
								v-model="formModelData.discountInterestAmount"
								:precision="0"
								style="margin-right: 10px"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountInterestAmount}` }}
						</a-form-item>
						<a-form-item label="Discount on Penalty" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountPenaltyAmount"
								v-model="formModelData.discountPenaltyAmount"
								:precision="0"
								style="margin-right: 10px"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountPenaltyAmount}` }}
						</a-form-item>
						<a-form-item label="Discount on Other Fees" :label-col="{ span: 6 }" labelAlign="left">
							<a-input-number
								:min="0"
								:max="apiDiscountData.discountOtherAmount"
								v-model="formModelData.discountOtherAmount"
								:precision="0"
								style="margin-right: 10px"
							/>
							{{ `${$t('table.finance.repayment.discTips')}${apiDiscountData.discountOtherAmount}` }}
						</a-form-item>
					</div>
				</a-form-model-item>
				<a-form-model-item :label="$t('table.finance.cloum.reson')" v-if="formModelData.isDiscount === 1">
					<a-textarea v-model="formModelData.extraReason" :maxLength="300" style="width: 400px" :rows="4" />
				</a-form-model-item>
				<a-form-model-item label="Choose the loan to repay" v-if="loanTypeRepayList.length > 0">
					<a-checkbox-group v-model="formModelData.repayLoan">
						<a-checkbox :value="settle.value" v-for="settle in loanTypeRepayList" :key="settle.value">
							{{ $t(`table.applyInfo.financialLabel.loanMap.${settle.loanType}`) }}
						</a-checkbox>
					</a-checkbox-group>
				</a-form-model-item>
				<a-form-model-item label="Early Settlement" v-if="!parentSource">
					<a-radio-group v-model="needEarlySettlement" @change="needEarlySettlementChange" :disabled="whetherToOverpay">
						<a-radio :value="0"> No </a-radio>
						<a-radio :value="1"> Yes </a-radio>
					</a-radio-group>
					<div v-if="needEarlySettlement === 1">
						<a-checkbox-group @change="setCheckedVal">
							<span v-for="settle in settlApiList" :key="settle.id">
								<a-checkbox :value="settle.loanType" @change="getEstimateSettlement" style="margin-right: 40px">
									{{ $t(`table.applyInfo.financialLabel.loanMap.${settle.loanType}`) }}
								</a-checkbox>
								<div v-if="settle.checked === true">
									Please select the period that early settled:
									<a-select v-model="settle.installment" style="width: 200px" @change="getEstimateSettlement(settle.loanType, settle.installment)">
										<a-select-option :value="instal" v-for="instal in settle.installmentList" :key="instal">
											{{ instal }}
										</a-select-option>
									</a-select>
								</div>
							</span>
						</a-checkbox-group>
						<a-form-model-item>Actual Repayment：{{ estimateSettlementData.hadPaidAmount }}</a-form-model-item>
						<a-form-model-item>Remaining Outstanding：{{ estimateSettlementData.outstandingAmount }}</a-form-model-item>
					</div>
				</a-form-model-item>
				<a-form-model-item :label="$t('table.finance.cloum.check')">
					<a-select v-model="formModelData.needClosedLoan" :disabled="whetherToOverpay || needEarlySettlement === 1">
						<a-select-option :value="1">{{ $t('page.yes') }}</a-select-option>
						<a-select-option :value="0">{{ $t('page.no') }}</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-form-model>
			<template slot="footer">
				<a-button key="back" @click="repayModalShow = false"> Cancel </a-button>
				<a-button @click="previewRepay"> Preview </a-button>
				<a-button key="submit" type="primary" @click="handModalOk"> Save </a-button>
			</template>
		</a-modal>
		<a-modal v-drag-modal v-model="previewRepayTable" title="Repayments Preview" width="85%" :footer="false" :destroyOnClose="true">
			<repayments-plan-list
				style="margin-bottom: 10px"
				:repayPlan.sync="previewRepayParams.repayPlan"
				:insurancePlans.sync="previewRepayParams.insurancePlans"
				:emergencyPlans.sync="previewRepayParams.emergencyPlans"
				:overpaidDetail.sync="previewRepayParams.overpaidDetail"
				:loanType="previewRepayParams.columnLoanType"
			></repayments-plan-list>
		</a-modal>
	</div>
</template>
<script>
import moment from 'moment'
import RepaymentsPlanList from '@/views/businessComponents/RepaymentsPlanList'
import {
	apiPreview,
	apiDiscountAmount,
	apiEstimateSettlement,
	apiLoanTransaction,
	apiFindLoanByName,
	apiUploadLoanTransaction,
	apiCheckLoanTransaction,
	apiOverpaidRepay,
	apiOverpaidUpload,
	apiDisburseEarlyInfo,
	apiDisburseRepayInfo
} from '@/api/loan'
import { apiMpesaUploadRepay, apiMpesaUploadOverpaid, apiDtbUploadRepay, apiDtbUploadOverpaid } from '@/api/accountChecking'
export default {
	components: { RepaymentsPlanList },
	data() {
		return {
			dateFormat: 'YYYY-MM-DD HH:mm:ss',
			repayModalShow: false,
			loanColumnData: {},
			parentSource: '',
			loanDataSource: [],
			selectedRows: [],
			selectedRowKeys: [],
			needAutoSearch: false,
			searchKeyword: '',
			whetherToOverpay: false,
			needAutoFilling: false,
			formModelData: {
				loanId: '',
				paymentId: '',
				transactionTypeEnum: 0,
				carPlateNo: '',
				transactionDate: '',
				transactionTime: null,
				transactionFiles: '',
				amount: '',
				isDiscount: 0,
				discountInterestAmount: 0,
				discountPenaltyAmount: 0,
				discountTrackingAmount: 0,
				discountMaintenanceAmount: 0,
				discountOtherAmount: 0,
				extraReason: '',
				needClosedLoan: 0,
				settlementParams: [],
				repayLoan: [0]
			},
			settlApiList: [],
			needEarlySettlement: 0,
			previewRepayTable: false,
			previewRepayParams: {
				repayPlan: [],
				insurancePlans: [],
				emergencyPlans: [],
				overpaidDetail: [],
				columnLoanType: ''
			},
			apiDiscountData: {
				discountInterestAmount: 0, //可平账的利息
				discountPenaltyAmount: 0, //可平账的罚息
				discountTrackingAmount: 0, //可平账的gps费用
				discountMaintenanceAmount: 0, //可平账的maintenance金额
				discountOtherAmount: 0 //可平账的其他费用
			},
			estimateSettlementData: {
				currentRepayAmount: null, //这笔还款还的钱
				hadPaidAmount: null, //已经还了多少钱
				outstandingAmount: null //剩余待还的钱
			},
			loanTypeRepayList: []
		}
	},
	created() {
		this.$nextTick(() => {
			// apiDisburseEarlyInfo()
		})
	},
	computed: {
		loanColumns() {
			return [
				{
					// 案件ID
					title: this.$t('table.finance.cloums.loanId'),
					dataIndex: 'casePrefix',
					align: 'center',
					width: 150,
					customRender: (v, o) => (v ? v + '-' + o.caseId : '')
				},
				{
					// 用户名
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					// 联系方式
					title: this.$t('table.applyInfo.cloums.mobile'),
					dataIndex: 'mobiles',
					align: 'center',
					customRender: (v) => (v ? v.join('/') : '')
				},
				{
					// 放款金额
					title: this.$t('table.applyInfo.cloums.putAmount'),
					dataIndex: 'disburseAmount',
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'money_slot' }
				},
				{
					// 待还金额
					title: this.$t('table.applyInfo.cloums.awaitAmount'),
					dataIndex: 'totalOutstanding',
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'money_slot' }
				},

				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		},
		installmentsOptions() {
			let arr = []
			for (let index = 1; index < this.loanColumnData.installmentsNumbers + 1; index++) {
				arr.push(index)
			}
			return arr
		}
	},
	methods: {
		moment: moment,
		resetFormModel() {
			this.formModelData = {
				loanId: '',
				paymentId: '',
				transactionTypeEnum: 0,
				carPlateNo: '',
				transactionDate: '',
				transactionTime: null,
				transactionFiles: '',
				amount: '',
				isDiscount: 0,
				discountInterestAmount: 0,
				discountPenaltyAmount: 0,
				discountTrackingAmount: 0,
				discountMaintenanceAmount: 0,
				discountOtherAmount: 0,
				extraReason: '',
				needClosedLoan: 0,
				settlementParams: [],
				repayLoan: null
			}
		},
		uploadRepayment(loanId, formModelData, parentSource) {
			this.resetFormModel()
			this.needEarlySettlement = 0
			this.needEarlySettlementChange()
			this.needAutoSearch = loanId ? true : false
			!formModelData ? '' : (this.formModelData = { ...this.formModelData, ...formModelData })
			this.parentSource = parentSource
			this.needAutoFilling = parentSource !== undefined
			if (this.needAutoSearch) {
				this.searchKeyword = loanId
				this.searchLoan()
			}
			this.repayModalShow = true
		},
		handModalOk() {
			if (!this.loanColumnData.loanId) {
				this.$message.error('please choose loan')
				return false
			} else if (!this.formModelData.paymentId) {
				this.$message.error('please input paymentId')
				return false
			} else if (!this.formModelData.transactionDate) {
				this.$message.error('please input time')
				return false
			} else if (this.formModelData.amount === '') {
				this.$message.error('please input amount')
				return false
			}
			if (this.formModelData.transactionTypeEnum === 2 && this.loanColumnData.carInfos.length > 1) {
				if (this.formModelData.carPlateNo === '') {
					this.$message.error('please select Registration No')
					return false
				}
			}
			if (
				this.formModelData.isDiscount === 1 &&
				!(
					this.formModelData.discountTrackingAmount +
						this.formModelData.discountInterestAmount +
						this.formModelData.discountPenaltyAmount +
						this.formModelData.discountMaintenanceAmount +
						this.formModelData.discountOtherAmount >
					0
				)
			) {
				this.$message.error('Discount Sum Amount Cannot Be 0')
				return false
			}
			if (!this.formModelData.extraReason && this.formModelData.isDiscount === 1) {
				this.$message.error('please input Discount Reason')
				return false
			}
			this.formModelData.transactionDate = this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD HH:mm:ss')
			if (this.parentSource === 'bankAccountChecking') {
				if (!this.formModelData.transactionTime) {
					this.$message.error('please input time')
					return false
				}
				this.formModelData.transactionDate =
					this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD') +
					' ' +
					this.moment(this.formModelData.transactionTime).format('HH:mm:ss')
			}
			if (this.needEarlySettlement === 1) {
				let arrBool = true
				for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
					if (!this.formModelData.settlementParams[index].installment) {
						this.$message.error('Please select the period')
						arrBool = false
						break
					}
				}
				if (!arrBool) {
					return arrBool
				}
			}
			this.$confirm({
				title: '',
				content:
					this.formModelData.needClosedLoan === 1
						? this.whetherToOverpay
							? this.$t('table.applyInfo.msgMap.msg6')
							: this.$t('table.applyInfo.msgMap.msg5')
						: this.$t('table.applyInfo.msgMap.msg1'),
				onOk: () => {
					if (this.parentSource === 'bankAccountChecking') {
						this.whetherToOverpay
							? apiDtbUploadOverpaid({
									paymentId: this.formModelData.paymentId,
									loanId: this.loanColumnData.loanId,
									transactionDate: this.formModelData.transactionDate
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiDtbUploadRepay({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					} else if (this.parentSource === 'mapesaAccountChecking') {
						this.whetherToOverpay
							? apiMpesaUploadOverpaid({
									paymentId: this.formModelData.recordId, //mpesa离线还款记录id
									loanId: this.loanColumnData.loanId //案件id
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiMpesaUploadRepay({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					} else if (this.parentSource === 'mpesaRepayment') {
						this.whetherToOverpay
							? apiOverpaidUpload({
									recordId: this.formModelData.recordId, //mpesa离线还款记录id
									loanId: this.loanColumnData.loanId //案件id
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiUploadLoanTransaction({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					} else if (this.parentSource === 'checkRepayment') {
						this.whetherToOverpay
							? apiOverpaidUpload({
									recordId: this.formModelData.recordId, //mpesa离线还款记录id
									loanId: this.loanColumnData.loanId //案件id
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiCheckLoanTransaction({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					} else {
						this.whetherToOverpay
							? apiOverpaidRepay({
									loanId: this.loanColumnData.loanId, //案件id
									repayType: this.formModelData.transactionTypeEnum, //还款类型，同录入还款的还款类型
									paymentId: this.formModelData.paymentId, //还款id
									transactionDate: this.formModelData.transactionDate, //还款时间
									transactionFiles: this.formModelData.transactionFiles, //还款凭证
									carPlateNo: this.formModelData.carPlateNo, //收车时候，多辆车需要填是哪辆车
									overpaidAmount: this.formModelData.amount //超额偿还金额
							  }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
							: apiLoanTransaction({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
									this.repayModalShow = false
									this.$emit('repaymentsInitTable', 1)
							  })
					}
				},
				onCancel() {}
			})
		},
		previewRepay() {
			if (!this.loanColumnData.loanId) {
				this.$message.error('please choose loan')
				return false
			}
			if (this.formModelData.amount === '') {
				this.$message.error('amount is empty!')
				return false
			}
			if (this.needEarlySettlement === 1) {
				let arrBool = true
				for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
					if (!this.formModelData.settlementParams[index].installment) {
						this.$message.error('Please select the period')
						arrBool = false
						break
					}
				}
				if (!arrBool) {
					return arrBool
				}
			}
			if (this.whetherToOverpay) {
				this.$message.error('Loan Cleared,please check it in Details!')
				return false
			}
			if (!this.formModelData.transactionDate) {
				this.$message.error('Actual Repayment Time is empty!')
				return false
			}
			this.formModelData.transactionDate = this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD HH:mm:ss')
			apiPreview({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
				this.previewRepayTable = true
				this.previewRepayParams = {
					repayPlan: res.loanRepaymentPreviewVos,
					insurancePlans: res.loanInsurancePreviewVos,
					emergencyPlans: res.loanEmergencyPreviewVos,
					overpaidDetail: res.overpaidDetail,
					columnLoanType: this.loanColumnData.loanType
				}
			})
		},
		searchLoan() {
			this.selectedRows = []
			this.selectedRowKeys = []
			if (!this.searchKeyword) {
				this.$message.error('name is required')
				return false
			}
			let keyword = ''
			if (/^[A-Za-z]{3} ?[0-9]{3}[A-Za-z]$/.test(this.searchKeyword)) {
				keyword = this.searchKeyword.replace(/\s*/g, '')
			} else {
				keyword = this.searchKeyword
			}
			apiFindLoanByName({ keyword: keyword }).then((res) => {
				if (res.content.length === 0) {
					this.$message.success('Search Success')
				}
				this.loanDataSource = res.content
				this.loanDataSource.length === 1 ? this.changeRow([0], this.loanDataSource) : ''
			})
		},
		changeRow(k, r) {
			this.selectedRowKeys = k
			this.selectedRows = r
			this.loanColumnData = r[0]
			if (this.loanColumnData.loanStatus > 1004) {
				this.formModelData.isDiscount = 0
				this.formModelData.needClosedLoan = 1
				this.whetherToOverpay = true
			} else {
				this.formModelData.isDiscount = 0
				this.formModelData.needClosedLoan = 0
				this.whetherToOverpay = false
			}
			this.getApiDisburseEarlyInfo()
			this.getDiscountAmount()
		},
		getDiscountAmount() {
			apiDiscountAmount({ loanId: this.loanColumnData.loanId }).then((res) => {
				this.apiDiscountData = res
			})
		},
		getEstimateSettlement(loanType, installment) {
			this.formModelData.settlementParams.map((item) => {
				if (item.loanType === loanType) {
					item.installment = installment
				}
			})
			if (!this.loanColumnData.loanId) {
				// this.$message.error('Please choose loan')
				return false
			}
			if (!this.formModelData.transactionDate) {
				// this.$message.error('Actual Repayment Time is empty!')
				return false
			}
			let arrBool = true
			for (let index = 0; index < this.formModelData.settlementParams.length; index++) {
				if (!this.formModelData.settlementParams[index].installment) {
					// this.$message.error('Please select the period')
					arrBool = false
					break
				}
			}
			if (!arrBool) {
				return arrBool
			}
			this.formModelData.transactionDate = this.moment(this.formModelData.transactionDate).format('YYYY-MM-DD HH:mm:ss')
			apiEstimateSettlement({ ...this.formModelData, loanId: this.loanColumnData.loanId }).then((res) => {
				this.estimateSettlementData = res
			})
		},
		needEarlySettlementChange() {
			if (this.needEarlySettlement === 1) {
				this.formModelData.needClosedLoan = 0
			} else {
				this.formModelData.settlementParams = []
			}
		},
		// 查看详情
		Detail(obj) {
			const pageDetail = obj.type === 'disbursed' ? 'lendingdetail' : 'applydetail'
			let routeData = this.$router.resolve({
				name: `menus.customerManagementMenu.${pageDetail}`,
				query: {
					id: obj.loanId
				}
			})
			this.$store.commit('tabs/addTab', {
				name: `menus.customerManagementMenu.${pageDetail}`,
				fullPath: `/home/customerManagement/${pageDetail}?id=${obj.loanId}`
			})
			window.open(routeData.href, '_blank')
			this.$store.commit('tabs/setActiveTab', '/home/finance/repayment')
		},
		getApiDisburseEarlyInfo() {
			apiDisburseEarlyInfo({ loanId: this.loanColumnData.loanId }).then((res) => {
				this.settlApiList = res.map((item) => {
					item.installmentList = Array.from({ length: item.installment }, (v, k) => k + 1)
					item.checked = false
					item.installment = null
					return item
				})
			})
			apiDisburseRepayInfo({ loanId: this.loanColumnData.loanId }).then((res) => {
				this.loanTypeRepayList = res.map((item) => {
					const loan = {}
					loan.loanType = item
					if (item === 7) {
						loan.value = 1
					} else {
						loan.value = item > 4 ? item - 3 : 1
					}
					return loan
				})
			})
		},
		setCheckedVal(checkedValues) {
			this.formModelData.settlementParams = []
			this.settlApiList.map((item) => {
				item.checked = checkedValues.includes(item.loanType)
				if (item.checked) {
					this.formModelData.settlementParams.push({
						loanType: item.loanType,
						settlementLoanId: item.settlementLoanId,
						installment: item.installment
					})
				}
				return item
			})
		}
	}
}
</script>

<style scoped lang="less">
.ant-input,
.ant-input-number,
.ant-select {
	width: 300px;
}
</style>
